import React, { useEffect, useState } from 'react';
import { Box, Text, useColorMode } from '@chakra-ui/react';
import Slider from 'react-slick';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; 
import PopCard from './PopCard'; 

const Popular = () => {
  const { colorMode } = useColorMode();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchPopularProducts = async () => {
      try {
        const response = await fetch('/api/products');
        const data = await response.json();
        console.log('API response:', data); 

        const popularProducts = data.filter(product => product.popular === 'Yes');

        const sortedProducts = popularProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setProducts(sortedProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
        setProducts([]); 
      }
    };

    fetchPopularProducts();
  }, []);

  const NextArrow = ({ onClick }) => (
    <Box
    as={FaArrowRight}
    onClick={onClick}
    cursor="pointer"
    color={colorMode === 'dark' ? 'black' : 'black'}
    position="absolute"
    right="-29px"
    top="50%"
    transform="translateY(-50%)"
    zIndex="1"
    fontSize={{ base: '24px', md: '26px', lg: '26px' }} 
    bg={colorMode === 'dark' ? 'gray.200' : 'gray.300'} 
    borderRadius="50%" 
    p="2" 
    _hover={{ bg: colorMode === 'dark' ? 'gray.500' : 'gray.500' }} 
  />
  );

  const PrevArrow = ({ onClick }) => (
    <Box
    as={FaArrowLeft}
    onClick={onClick}
    cursor="pointer"
    color={colorMode === 'dark' ? 'black' : 'black'}
    position="absolute"
    left="-29px"
    top="50%"
    transform="translateY(-50%)"
    zIndex="1"
    fontSize={{ base: '24px', md: '26px', lg: '26px' }} 
    bg={colorMode === 'dark' ? 'gray.200' : 'gray.300'} 
    borderRadius="50%" 
    p="2" 
    _hover={{ bg: colorMode === 'dark' ? 'gray.500' : 'gray.500' }} 
  />
  );


  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6, 
    slidesToScroll: 6, 
    nextArrow: <NextArrow />, 
    prevArrow: <PrevArrow />, 
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 4, 
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 3, 
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480, 
        settings: {
          slidesToShow: 2, 
          slidesToScroll: 2,
        },
      },
    ],
  };


  const backgroundColor = colorMode === 'dark' ? 'black' : 'white';
  const headingColor = colorMode === 'dark' ? 'whiteAlpha.800' : 'blackAlpha.800';

  return (
    <Box
      w="full"
      py="0"
      mt='-7'
      bgColor={backgroundColor}
      p="8" 
    >
      <Text
        fontSize={{ base: '2xl', md: '2xl', lg: '3xl' }} 
        fontWeight="md"
        textAlign="center"
        letterSpacing='wide'
        mb="0"
        mt="2"
        transition="all 0.5s ease" 
          _hover={{
            transform: 'scale(1)', 
            letterSpacing: '0.05em',
            textDecor: 'underline' 
          }}
        color={headingColor} 
      >
        Popular in Regent NX
      </Text>

      {products.length === 0 ? (
        <Text textAlign="center">No popular products available</Text> 
      ) : (
        <Slider {...settings}>
          {products.map((product) => (
            <PopCard key={product._id} product={product} />
          ))}
        </Slider>
      )}
    </Box>
  );
};

export default Popular;
